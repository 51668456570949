<template>
  <div>
    <section class="cont_01">
      <form @submit="onSearch">
        <div class="searchform_cont_01">
          <span style="margin-right: 0">
            <label>기간조회</label>
            <date-picker
              v-model="startDate"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <span class="ml_04 mr_04">~</span>
            <date-picker
              v-model="endDate"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
          </span>
          <span>
            <label style="opacity: 0">검색</label>
            <base-button type="primary" nativeType="submit">검색</base-button>
          </span>
        </div>
      </form>
    </section>

    <section class="tbl_middle_box">
      <h3 class="tbl_count_title">일별 회원가입 현황</h3>
      <span class="btn_right">
        <base-button
          type="download"
          @click.prevent.stop="
            download(
              'user/statistics-general-register-daily?download=xls',
              '일반 회원가입 현황',
              'xls',
              '',
              'user/statistics-client-register-daily?download=xls'
            )
          "
          >엑셀 다운로드</base-button
        >
      </span>

      <div v-if="userRegisterDaily.loading">
        <clip-loader color="#ff2c55">Loading</clip-loader>
      </div>
      <div v-else-if="!userRegisterDaily.empty">
        <multi-line-chart
          :label="userRegisterDaily.label"
          :date="userRegisterDaily.date"
          :data="userRegisterDaily.data"
        ></multi-line-chart>
      </div>
      <div v-else style="text-align: center; font-weight: bold">
        차트 데이터가 없습니다
      </div>
    </section>
  </div>
</template>

<script>
/**
 * vuex
 */
import { mapGetters } from "vuex";

/**
 * client
 */
import client from "api-client";

// chart
import MultiLineChart from "../../../components/chart/BaseMultiLineChart.vue";
import moment from "moment";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  name: "UserDashboard",
  components: { MultiLineChart },
  data() {
    return {
      userRegisterDaily: {
        loading: true,
        empty: true,
        label: ["일반 회원 가입 수", "지자체 회원 가입 수"],
        date: [],
        data: [],
      },
      startDate: "",
      endDate: "",
    };
  },
  watch: {
    // startDate() {
    //   this.fetch();
    // },
  },
  created() {
    /**
     * created
     */
    if (!this.startDate && !this.endDate) {
      this.startDate = moment(new Date()).add(-30, "day").format("YYYY-MM-DD");
      this.endDate = moment(new Date()).format("YYYY-MM-DD");
    }
    this.$Progress.start();
    this.fetch();
  },
  mounted() {
    /**
     * mounted
     */
    this.$Progress.finish();
  },
  computed: {
    /**
     * computed
     */
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    /**
     * methods
     */
    download(url, name, type, params, url2) {
      params = {
        page: this.currentPage,
        size: this.pageSize,
        startDate: this.$date(new Date(this.startDate)).format("YYYY-MM-DD"),
        endDate: this.$date(new Date(this.endDate)).format("YYYY-MM-DD"),
        download: "xls",
      };
      if (url2) {
        url = `${API_URL}${SERVICE_API_URL}/${url}`;
        File.download(url, name, type, params);
        url2 = `${API_URL}${SERVICE_API_URL}/${url2}`;
        File.download(url2, "지자체 회원가입 현황", type, params);
      }
      url = `${API_URL}${SERVICE_API_URL}/${url}`;
      File.download(url, name, type, params);
    },

    onSearch(event) {
      event.preventDefault();
      this.fetch(1);
    },

    async fetchUserRegisterDaily() {
      let params = {
        startDate: this.$date(new Date(this.startDate)).format("YYYY-MM-DD"),
        endDate: this.$date(new Date(this.endDate)).format("YYYY-MM-DD"),
      };

      let date = [];
      let cnt = [];
      let cnt2 = [];

      // 일별 회원가입은 여러 API 응답값을 병합하기 때문에 await 필요.. 아래 API 요청이 대기 타지 않도록 따로 요청
      await client.generalUserRegisterDaily(params).then(
        (response) => {
          const { data } = response;
          const users = data.items;
          cnt = users.map((item) => item.cnt);
          date = users.map((item) => item.date);
        },
        () => {}
      );

      await client.clientRegisterDaily(params).then(
        (response) => {
          const { data } = response;
          const clients = data.items;
          cnt2 = clients.map((item) => item.cnt);
        },
        () => {}
      );
      this.userRegisterDaily.date = date;
      this.userRegisterDaily.data = [cnt, cnt2];
      this.userRegisterDaily.loading = false;
      this.userRegisterDaily.empty = cnt && cnt2 ? false : true;
    },

    async fetch() {
      this.userRegisterDaily.loading = true;
      if (!this.startDate || !this.endDate) {
        alert("날짜를 입력해주세요");
      } else {
        this.fetchUserRegisterDaily();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
@import "../../../assets/scss/common.scss";
</style>
